<template>
  <div>
    <!--==================================================-->
    <!-- Start Toptech Footer Area Style two-->
    <!--==================================================-->
    <div class="footer-area style-two">
      <div class="container">
        <div class="row footer-top section-border align-items-center">
          <div class="col-lg-5 col-md-12">
            <div class="footer-title style-three">
              <h2>
                Are You Ready for Your<br />
                Business Success?
              </h2>
              <div class="footer-widget-desc">
                <p>
                  Make your businesses scale operations, personalize services,
                  and make the data-driven decisions faster.
                </p>
              </div>
              <div class="section-btn style-three">
                <!-- <a href="#">READ MORE<i class="bi bi-arrow-right"></i></a> -->
                <router-link :to="'/conversational-AI'" title="Request"
                  >READ MORE</router-link
                >
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="footer-shape">
              <img src="/assets/images/home-2/footer-shape.png" alt="shape" />
            </div>
          </div>
          <div class="col-lg-3 col-md-12">
            <div class="footer-info-box">
              <div class="footer-info-content">
                <h4 class="footer-info-sub-title">
                  <i class="fab fa-envelope-o"></i>
                  <p class="footer-info-desc">Connect us at</p>
                  <!-- <a href="#">Connect us at</a> -->
                </h4>
                <h1 class="footer-info-title">info@assistantdesk.io</h1>
                <p class="footer-info-desc">
                  Send an email to our marketing expert. We will assist you.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row footer-main">
          <div class="col-xl-3 col-lg-3 col-md-6">
            <div class="footer-logo">
              <img
                src="/assets/images/home-2/logo.png"
                alt="logo"
                width="70%"
              />
            </div>
            <div class="footer-wiget-menu">
              <ul class="wiget-menu">
                <li class="mb-3">
                  <h6>India office:</h6>
                  <a
                    >DF-52, Scheme no. 74, Vijaynagar,<br />
                    Indore, (M.P.) (452010)</a
                  >
                </li>
                <li>
                  <h6>Singapore Office:</h6>
                  <a
                    >65 Tampines Avenue 1, The Tropica, <br />Singapore (529778)
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-xl-2 col-lg-3 col-md-6">
            <div class="footer-wiget-title">
              <h3>Platform</h3>
            </div>
            <div class="footer-wiget-menu">
              <ul>
                <li>
                  <a href="#" @click="menuClick('ConversationalIntelligence')"
                    >Conversational Intelligence</a
                  >
                </li>
                <li>
                  <a href="#" @click="menuClick('AssistantDesk')"
                    >AI Assistant Desk</a
                  >
                </li>
                <li><a href="#" @click="menuClick('VoiceAi')">Voice AI</a></li>
              </ul>
            </div>
          </div>
          <div class="col-xl-2 col-lg-2 col-md-6">
            <div class="footer-wiget-title">
              <h3>Solution</h3>
            </div>
            <div class="footer-wiget-menu">
              <ul>
                <li>
                  <a href="#" @click="menuClick('CustomerSolution')"
                    >Customer Service</a
                  >
                </li>
                <li>
                  <a href="#" @click="menuClick('EmployeeExperience')"
                    >Employee Experience</a
                  >
                </li>
                <li>
                  <a href="#" @click="menuClick('ConversationalAIHealthcare')"
                    >Healthcare</a
                  >
                </li>
                <li>
                  <a href="#" @click="menuClick('AIOperationalDueDeligence')"
                    >Financial</a
                  >
                </li>
              </ul>
            </div>
          </div>

          <div class="col-xl-2 col-lg-2 col-md-6">
            <div class="footer-wiget-title">
              <h3>Integration</h3>
            </div>
            <div class="footer-wiget-menu">
              <ul>
                <li><a href="#">Hubspot</a></li>
                <li><a href="#">Freshdesk</a></li>
                <li>
                  <a href="#"
                    >vTiger
                    <!-- <span style="font-size: 14px; font-style: italic"
                    >and many more...</span
                  > -->
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-md-6">
            <div class="footer-wiget-title">
              <h3>CONTACT US</h3>
            </div>
            <form @submit.prevent="submitForm">
              <div class="footer-newslatter-box">
                <p
                  v-if="successMessage"
                  class="text text-success"
                  :class="{ 'text-active': successMessage != null }"
                >
                  {{ successMessage }}
                </p>
                <input
                  type="text"
                  placeholder="Enter Your Email..."
                  v-model="formFields.email_address"
                />
                <button type="submit">Send</button>
                <span v-if="v$.email_address.$error" class="text text-danger">
                  Email address is Required.</span
                >
              </div>
            </form>
            <p class="footer-desc2">
              We are 24X7 available to respond your queries.
            </p>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="footer-bottom-area">
            <div class="row footer-bottom">
              <div class="col-lg-12 col-md-8">
                <div class="footer-bottom-content">
                  <p>
                    © 2024 IRA Softwares. All rights reserved.
                    <a
                      href="#"
                      @click="menuClick('TermsofServices')"
                      class="me-3 ms-auto"
                      >Terms of Services</a
                    >
                    <a href="#" @click="menuClick('PrivacyPolicy')">
                      Privacy Policy</a
                    >
                  </p>
                </div>
              </div>
              <!-- <div class="col-lg-6 col-md-4">
              <div class="footer-botton-social">
                <ul>
                  <li>
                    <a href="#"><i class="fab fa-facebook-f"></i></a>
                  </li>
                  <li>
                    <a href="#"><i class="fab fa-twitter"></i></a>
                  </li>
                  <li>
                    <a href="#"><i class="fab fa-dribbble"></i></a>
                  </li>
                  <li>
                    <a href="#"><i class="fab fa-linkedin-in"></i></a>
                  </li>
                </ul>
              </div>
            </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--==================================================-->
    <!-- End Toptech Footer Area Style two-->
    <!--==================================================-->

    <!--==================================================-->
    <!-- Start Toptech Scroll Up-->
    <!--==================================================-->
    <!-- <div class="prgoress_indicator active-progress">
      <svg
        class="progress-circle svg-content"
        width="100%"
        height="100%"
        viewBox="-1 -1 102 102"
      >
        <path
          d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
          style="
            transition: stroke-dashoffset 10ms linear 0s;
            stroke-dasharray: 307.919, 307.919;
            stroke-dashoffset: 212.78;
          "
        ></path>
      </svg>
    </div> -->
  </div>

  <!-- Chatboat Design -->
  <div class="msp_chat_box">
    <div class="msp_header">
      <!-- <span class="back_chat_button header_icon">
        <svg
          focusable="false"
          viewBox="0 0 24 24"
          aria-hidden="true"
          fill="#13abe8"
        >
          <path
            d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"
          ></path>
        </svg>
      </span> -->
      <div class="company_name">
        <h2 class="m-0">Assistant Desk</h2>
        <span class="sub_heading">Usually reply in a few minutes</span>
      </div>
      <span class="close_chat_button header_icon">
        <svg
          focusable="false"
          viewBox="0 0 24 24"
          aria-hidden="true"
          fill="#13abe8"
          @click="msp_show_boat"
        >
          <path
            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
          ></path>
        </svg>
      </span>
    </div>

    <ul class="msp_message_box">
      <span class="time_date days_time"><b>06-02-2025</b></span>
      <li class="in_message message">
        <div class="boat_icon">
          <svg
            class="chat_icon show"
            width="56px"
            height="56px"
            viewBox="0 0 22 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.87713 0.0917969C2.5487 0.0917969 0.661133 1.97936 0.661133 4.30779V10.3598C0.661133 12.6882 2.5487 14.5758 4.87713 14.5758H16.9051C18.8952 14.6617 20.5592 16.0055 21.122 17.8321C21.1357 17.8767 21.1767 17.9077 21.2234 17.9077C21.2813 17.9077 21.3283 17.8607 21.3283 17.8028V10.5643C21.3315 10.4965 21.3331 10.4284 21.3331 10.3598V4.3078C21.3331 1.97936 19.4456 0.0917969 17.1171 0.0917969H4.87713Z"
              fill="white"
            ></path>
            <rect
              x="4.87695"
              y="4.7832"
              width="2.584"
              height="4.42"
              rx="1.292"
              fill="#13abe8"
            ></rect>
            <rect
              x="10.998"
              y="4.7832"
              width="2.584"
              height="4.42"
              rx="1.292"
              fill="#13abe8"
            ></rect>
          </svg>
        </div>
        <div class="message_text">
          <span class="time_date">Assistant Desk • 4:54 PM</span>
          <p>
            Hello 👋 I'm Chatty AI assistant, here to help you find what you're
            looking for. How can I help you?
          </p>
        </div>
      </li>
      <li class="out_message message">
        <div class="message_text">
          <span class="time_date">4:54 PM</span>
          <p>How long does delivery take?</p>
        </div>
      </li>
    </ul>

    <div class="msp_footer">
      <div class="type_message">
        <input name="" id="" placeholder="Type Your Message" />
        <span class="msp_send_button" title="Send">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
          >
            <path
              d="M18.235 2.18603C18.667 0.991029 17.509 -0.166971 16.314 0.266029L1.70901 5.54803C0.510012 5.98203 0.365011 7.61803 1.46801 8.25703L6.13001 10.956L10.293 6.79303C10.4816 6.61087 10.7342 6.51008 10.9964 6.51236C11.2586 6.51463 11.5094 6.6198 11.6948 6.80521C11.8802 6.99062 11.9854 7.24143 11.9877 7.50363C11.99 7.76583 11.8892 8.01843 11.707 8.20703L7.54401 12.37L10.244 17.032C10.882 18.135 12.518 17.989 12.952 16.791L18.235 2.18603Z"
              fill="white"
            ></path>
          </svg>
        </span>
      </div>
    </div>
  </div>

  <div id="msp_chat_boat_button" class="msp_chat_boat_btn">
    <svg
      class="chat_icon show"
      width="56px"
      height="56px"
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      @click="msp_show_boat"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.87713 0.0917969C2.5487 0.0917969 0.661133 1.97936 0.661133 4.30779V10.3598C0.661133 12.6882 2.5487 14.5758 4.87713 14.5758H16.9051C18.8952 14.6617 20.5592 16.0055 21.122 17.8321C21.1357 17.8767 21.1767 17.9077 21.2234 17.9077C21.2813 17.9077 21.3283 17.8607 21.3283 17.8028V10.5643C21.3315 10.4965 21.3331 10.4284 21.3331 10.3598V4.3078C21.3331 1.97936 19.4456 0.0917969 17.1171 0.0917969H4.87713Z"
        fill="white"
      ></path>
      <rect
        x="4.87695"
        y="4.7832"
        width="2.584"
        height="4.42"
        rx="1.292"
        fill="#13abe8"
      ></rect>
      <rect
        x="10.998"
        y="4.7832"
        width="2.584"
        height="4.42"
        rx="1.292"
        fill="#13abe8"
      ></rect>
    </svg>
    <svg
      class="chat_icon"
      width="56px"
      height="56px"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style="width: 56px; height: 56px"
    >
      <circle cx="30" cy="30" r="30" fill="#13abe8"></circle>
      <path
        d="M17.9089 27.7478C17.9079 28.0194 17.9679 28.2879 18.0843 28.5333C18.2007 28.7788 18.3706 28.995 18.5815 29.1662L29.4896 37.9496C29.8149 38.2171 30.2229 38.3633 30.644 38.3633C31.0651 38.3633 31.4732 38.2171 31.7985 37.9496L42.7065 28.857C43.0778 28.5484 43.3113 28.1048 43.3556 27.624C43.3999 27.1431 43.2514 26.6643 42.9429 26.293C42.6343 25.9216 42.1908 25.688 41.7101 25.6437C41.2294 25.5994 40.7507 25.7479 40.3795 26.0565L30.6349 34.1853L20.8904 26.3293C20.6235 26.107 20.2986 25.9657 19.954 25.9223C19.6094 25.8788 19.2596 25.9351 18.9459 26.0843C18.6322 26.2335 18.3679 26.4695 18.1841 26.7643C18.0003 27.0591 17.9048 27.4004 17.9089 27.7478Z"
        fill="white"
      ></path>
    </svg>
  </div>
</template>
<script>
import axios from "axios";
import { reactive, computed } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
export default {
  name: "CustomerSolution",
  data() {
    return {
      errorMessage: null,
      successMessage: null,
    };
  },
  setup() {
    const formFields = reactive({
      email_address: "",
      request: 5,
    });
    const rules = {
      email_address: {
        required,
        email,
      },
    };
    const v$ = useVuelidate(rules, formFields);
    return {
      formFields,
      v$,
    };
  },
  methods: {
    menuClick(url) {
      this.$router.push({ name: url });
      this.nameUrl = url;
    },
    async pushNotification() {
      const text_contect = { content: "Get requested by email" };
      const url =
        window.location.hostname == "localhost"
          ? " https://192.168.0.87:3003/api/user/createAdminNotification"
          : "https://hexadegree.com:3003/api/user/createAdminNotification";
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(text_contect),
        });
      } catch (error) {
        console.error("Error:", error);
      }
    },

    async submitForm() {
      this.v$.$validate();
      if (!this.v$.$error) {
        console.log("XXXXXXXXXXXXX", this.formFields);
        const url =
          window.location.hostname == "localhost"
            ? "https://192.168.0.87:3003/api/user/createContactUsByMail"
            : "https://hexadegree.com:3003/api/user/createContactUsByMail";
        try {
          const response = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(this.formFields),
          });
          if (response.ok) {
            this.pushNotification();
            const data = await response.json();
            this.successMessage = "request has been sent.";
            setTimeout(
              function () {
                this.successMessage = null;
              }.bind(this),
              3000
            );
            this.formFields.email_address = "";
            //  this.formFields={
            //   email_address: ""
            //  }
          } else {
            console.error("Error:", response.statusText);
          }
        } catch (error) {
          console.error("Error:", error);
        }
      }
    },

    msp_show_boat() {
      const chatButton = document.getElementById("msp_chat_boat_button");
      const chatIcons = document.querySelectorAll(".chat_icon");
      const messageBoxes = document.querySelectorAll(".msp_chat_box"); // Assuming multiple message boxes

      // Add an event listener for the click event
      chatButton.addEventListener("click", () => {
        // Toggle the 'show' class for each chat icon
        chatIcons.forEach((icon) => {
          icon.classList.toggle("show");
        });

        // Toggle the 'show' class for each message box
        messageBoxes.forEach((box) => {
          box.classList.toggle("show");
        });
      });
    },
  },
};
</script>
