import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import NotFound from "../views/NotFound.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/testpage",
    name: "TestPage",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/TestPage.vue"),
  },
  {
    path: "/request",
    name: "langdingPage",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/RequestDemo.vue"),
  },
  {
    path: "/conversational-AI",
    name: "ConversationalIntelligence",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/ConversationalIntelligence.vue"
      ),
  },
  {
    path: "/assistant-desk",
    name: "AssistantDesk",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AssistantDesk.vue"),
  },
  {
    path: "/voice-AI",
    name: "VoiceAi",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/VoiceAi.vue"),
  },
  {
    path: "/customer-service",
    name: "CustomerSolution",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/CustomerSolution.vue"),
  },
  {
    path: "/employee-experience",
    name: "EmployeeExperience",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/ EmployeeExperience.vue"
      ),
  },
  {
    path: "/conversational-AI-healthcare",
    name: "ConversationalAIHealthcare",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/ConversationalAIHealthcare.vue"
      ),
  },
  {
    path: "/about",
    name: "AboutUs",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutUs.vue"),
  },
  {
    path: "/contact",
    name: "ContactUs",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ContactUs.vue"),
  },
  {
    path: "/terms-services",
    name: "TermsofServices",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/TermsofServices.vue"),
  },
  {
    path: "/integration",
    name: "IntegrationPage",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/IntegrationPage.vue"),
  },
  {
    path: "/privacy",
    name: "PrivacyPolicy",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PrivacyPolicy.vue"),
  },
  {
    path: "/ai-operational-due-deligence",
    name: "AIOperationalDueDeligence",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/AIOperationalDueDeligence.vue"
      ),
  },

  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
